<template>
	<div v-if="isLoaded" class="container">
		<div class="back-top" id="opp-back-top" @click="handleBack">
      <div>
        <ion-icon name="arrow-back-outline" class="big-icon"></ion-icon>
      </div>
      <h2>Back</h2>
    </div>
		<hr class="good-line" />
		<h2 class="title">Your Granted Wishes</h2>
		<div class="second-bar">
			<div class="filter-btn">
        <button
          :class="{
            'filter-btn-unclicked': !isFiltering,
            'filter-btn-clicked': isFiltering,
          }"
          @click="isFiltering = !isFiltering"
        >
          <h4 v-if="!isFiltering">Show Additional Filters</h4>
          <h4 v-else>Hide Additional Filters</h4>
        </button>
      </div>
		</div>
		<Filter v-if="isFiltering" @apply-wish-filters="handleFilters" :isForHome="true" />
    <div v-if="!filteredWishes[0]" class="default-wishes">
      <h3>You have not granted any wishes yet.</h3>
    </div>
		<div class="Wishes">
			<WishCard
				v-for="wish in filteredWishes"
				@exitEdit="toggleEdit"
				@exitManage="handleReload"
        @change-target-chatter="changeTargetChatter"
				:key="wish.wishId"
				:wish="wish"
				:companyName="wish.company"
				:companyId="wish.companyId"
			/>
		</div>
	</div>
</template>

<script>
import { useRouter } from "vue-router";
import { ref, onMounted } from "vue";

import getUser from "@/composables/getUser";
import getMyAcceptedWishes from "@/composables/Profile/getMyAcceptedWishes";
import getWish from "@/composables/WishList/getWish";
import modifyDate from "@/composables/modifyDate";

import WishCard from "@/components/WishList/wishCard.vue";
import WishEdit from "@/components/WishList/wishEdit.vue";
import Filter from "@/components/WishList/wishFilter.vue";


export default {
	components:{
		WishCard,
		WishEdit,
		Filter,
	},
	emits: ["change-target-chatter"],
	async setup(props, { emit }) {
		const { user } = getUser();
		const router = useRouter();

		const isFiltering = ref(false);

		const myWishes = ref([]);
		const tempWish = ref(null);
		const wishes = ref([]);
		const filteredWishes = ref([]);
		const noSpecial = ref(null);
		noSpecial.value = /[$&+,:;=?[\]@#|{}'<>.^*()%!-/]/;
    const isLoaded = ref(false);

		const handleLoadMyWishes = async () => {
			let promises = [];
			let temp;
			await getMyAcceptedWishes(myWishes, user.value.uid, true);
			myWishes.value.forEach(myWish => { //!!!!!!!!!!!!!!
				// console.log(myWish)
				temp = Promise.resolve((async () => {
					tempWish.value = null;
					await getWish(tempWish, myWish.companyId, myWish.wishId);
					// console.log(tempWish);
					wishes.value.push({
						...tempWish.value,
						status: myWish.status,
            isMine: true,
						time: modifyDate(myWish.time.toDate()),
            studentId: myWish.studentId,
            messageTargetId: myWish.studentId
					});
				})());
				promises.push(temp);
			})
			await Promise.all(promises);
			// console.log(wishes.value);
			// console.log("handleLoadMyWishes completed");
		}

    onMounted(async () => {
      await handleLoadMyWishes();
		  filteredWishes.value = wishes.value;
      isLoaded.value = true;
    });

		const handleFilters = (filters) => {
			filteredWishes.value = wishes.value;
			if (filters.state) {
        filteredWishes.value = filteredWishes.value.filter(
          (wish) => {
            return filters.state.includes(wish.state);
          }
        );
      }
      if (filters.position) {
        filteredWishes.value = wishes.value.filter((wish) => {
          if (filters.position == null) {
            return true;
          } else if (noSpecial.value.test(filters.position)) {
            alert("Please do not type in special characters.");
            return true;
          }
          return wish.position.toLowerCase().match(filters.position.toLowerCase());
        });
      }
			if (filters.status) {
        filteredWishes.value = filteredWishes.value.filter(
          (wish) => {
            return filters.status.includes(wish.status);
          }
        );
      }
		}

    const changeTargetChatter = (chatterId) => {
      emit("change-target-chatter", chatterId);
    }

    const toggleEdit = (isChildEdit) => {
			// alert("isChildEdit: ", isChildEdit);
			if (isChildEdit==false){
				router.go();
			}
    }

		const handleBack = () => {
      router.go(-1);
    };

    const handleReload = () => {
      router.go();
    }

		return {isLoaded, isFiltering, filteredWishes, handleFilters, toggleEdit, handleBack, handleReload, changeTargetChatter}
	}
}
</script>

<style scoped>
.container{
	width: 90%;
  padding-top: 5vh;
  margin: 0 auto 0 auto;
	display: block;
}

.second-bar{
	display: flex;
	margin-top: 3vh;
	text-align: center;
}

.title{
	margin-top: 5vh;
}

.filter-btn {
  flex: 0 1 40%;
  text-align: left;
  transition: all 0.25s ease;
}

.filter-btn-unclicked {
  background-color: white;
  color: var(--purple-l);
  padding: 0.5vh 1vw;
  border: 0.3vh var(--purple-l) solid;
  border-radius: 1vh;
  transition: all 0.25s ease;
}

.filter-btn-unclicked:hover {
  color: var(--purple);
  border-color: var(--purple);
  cursor: pointer;
}

.filter-btn-clicked {
  background-color: var(--purple-l);
  color: white;
  padding: 0.5vh 1vw;
  border: 0.3vh var(--purple-l) solid;
  border-radius: 1vh;
  transition: all 0.25s ease;
}

.filter-btn-clicked:hover {
  border-color: var(--purple-400);
  background-color: var(--purple-400);
  cursor: pointer;
}

.default-wishes {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.default-wishes h3 {
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  margin: 15vh 0 15vh 0;
}
</style>
